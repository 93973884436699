.container {
  padding: 0.8rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 8rem;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.iconWrap {
  display: flex;
  align-items: last baseline;
  margin-left: 0.3rem;
}

.iconWrap img {
  display: flex;
  height: min-content;
  width: 28px;
}

.headerTitle h6 {
  display: flex;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.deliveryDate{
  position: relative;
}

.deliveryDate p {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  color: #8a8a8a;
}

.deliveryDate h6 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  text-align: right;
}

.deliveryDate span {
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  color: #8a8a8a;
  position: absolute;
  bottom: -1rem;
  right: 0;
}

.wholeChickenBanner {
  display: flex;
  justify-content: center;
}

.checkOutWrap {
  position: relative;
  background-color: #fcfcfc;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  color: #1d1e22;
  border-top: 1px solid #f6f6f6;
}

.checkOutWrap span {
  font-weight: 600;
  color: #1d1e22;
  font-size: 20px;
}

.checkOutWrap > p {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-decoration-skip-ink: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #8a8a8a;
}

.checkOutWrap button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 155px;
  width: 100%;
  gap: 0.3rem;
  background-color: #1d1e22;
  border: none;
  color: #eeeeee;
  font-weight: 600;
  padding: 0.8rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.asterikNote {
  width: 100%;
  place-content: center;
  justify-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  flex-direction: row !important;
  align-items: center !important;
  top: -1.5rem;
  color: #1d1e22 !important;
  font-size: 12px !important;
  text-wrap: nowrap;
  left: 0;
  background-color: #fcfcfc;
  gap: 0.5rem;
}

.asterikNote > p {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #bc1414;
  margin-top: 0.5rem;
}

@media screen and (max-width: 375px) {
  .asterikNote {
    text-wrap: wrap;
    font-size: 10.5px !important;
  }
}
