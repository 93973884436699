.container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
  padding: 0.8rem;
  background-color: white;
  width: 100%;
  user-select: none;
  position: relative;
  margin-bottom: 4rem;
  overflow: visible;
  /* padding-top: 4rem; */
  z-index: 10;
  margin-bottom: 5rem;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
}

.tileHeader {
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
}

/* .headerTitle img {
  display: flex;
  height: min-content;
  width: 28px;
} */

.headerTitle h6 {
  display: flex;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.iconWrap {
  display: flex;
  align-items: last baseline;
  margin-left: 0.3rem;
}

.iconWrap img {
  height: min-content;
  width: 20px;
  margin-bottom: 0.3rem;
}

.greeting p {
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  gap: 0.3rem;
}

.greeting p {
  font-weight: 600;
  font-size: 14px !important;
}

.greeting p:last-child {
  color: #8a8a8a;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  text-decoration-skip-ink: none;
}

.greeting span {
  font-size: 20px;
  font-weight: 700;
  text-wrap: nowrap;
  color: #bc1414;
}

.greeting img {
  width: 20px;
}

.deliveryDateWrap p {
  text-align: right;
  font-size: 12px;
  color: #8a8a8a;
  line-height: 22px;
}

.deliveryDateWrap span {
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 600;
  color: #1d1e22;
}

/* .content h3 {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  padding: 0.2rem 0;
  text-align: center;
  width: 60%;
  align-self: center;
  top: 0rem;
} */

.tileCarouselBtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tileImage {
  aspect-ratio: 16/9;
  width: 100%;
  position: relative;
}

.tileImage img {
  display: flex;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tileImage i {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.goatDetails {
  color: #374452;
}

.descriptionPopupOverlay {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(55, 68, 82, 0.4);
}

.visible {
  opacity: 1;
}

.descriptionTable {
  display: flex;
  flex-direction: column;
  height: min-content;
  position: relative;
  border-radius: 8px;
  width: 90%;
  padding: 20px;
  padding-top: 3rem;
  z-index: 1000;
  top: 3rem;
  background-color: #fff;
  opacity: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: opacity 0.5s ease;
}

.closeIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
}

.descriptionTable table {
  border-collapse: collapse;
  width: 100%;
}

.descriptionTable table tbody tr td {
  padding-bottom: 0.8rem;
  /* align-content: baseline; */
}

.descriptionTable table tr td:nth-child(3) {
  box-sizing: border-box;
  font-weight: 600;
  padding-left: 1rem;
}

.descriptionTable td:nth-child(1) {
  width: 60%;
}

.goatDetails span {
  font-weight: 600;
}

.quantityControl {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #f6f6f6;
  padding: 0.5rem 0 0 0.5rem;
  border-radius: 5px;
  /* border-bottom: 1px solid #374452; */
}

.controlPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 0.5rem;
  padding-right: 0.5rem;
}

.itemLabelWrap {
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
}

.itemLabelWrap > p {
  font-size: 14px;
  font-weight: 600;
  color: #1d1e22;
}

.quantityControl > span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.quantityLabels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quantityButtons {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #ffffff;
  width: 140px;
  box-sizing: border-box;
}

.quantityButtons p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  background-color: #1d1e22;
  padding: 0 0.8rem;
  height: 100%;
  width: 100%;
}

.quantityButtons button {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  font-size: 24px;
  border: none;
  border-width: 0;
  background-color: #1d1e22;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
  outline: none;
}

.quantityButtons button:last-child {
  border-bottom-right-radius: 5px;
}

.quantityButtons button:active {
  opacity: 0.9;
  background-color: rgb(29, 30, 34, 0.5);
}

/* .quantityButtons button {
  display: flex;
  justify-content: center;
  width: 22px;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  border-radius: 50%;
  box-sizing: border-box;
} */

/* .quantityButtons button:hover {
  background-color: #e0e0e0;
} */

.quantityButtons input {
  width: 40px;
  text-align: center;
  border: none;
  margin: 0 4px;
  font-size: 14px;
}

.price {
  display: flex;
  align-items: center;
}

.price p {
  text-align: right;
  color: #1d1e22;
  font-size: 16px;
  font-weight: 700;
}

.price span {
  font-size: 10px;
  font-weight: 500;
  color: #8a8a8a;
}

.addToCartWrap {
  display: flex;
  justify-content: right;
}

.addToCartButton {
  background-color: #608bc1;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 16px;
  transition: background-color 0.3s ease;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.availableNote {
  font-size: 12px;
  font-weight: 600;
  color: #1d1e22;
}

.availableNote span {
  color: #bc1414;
  font-size: 16px;
}
