@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.8rem;
  background-color: #f9f7f3;
  border-radius: 15px;
  padding: 0.6rem 0 0 0.6rem;
}

.title h6 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 41px;
  text-align: left;
  color: #1d1e22;
}

.meatImage {
  display: flex;
  justify-content: right;
}

.meatImage img {
  border-bottom-right-radius: 15px;
}
