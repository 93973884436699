.container {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #133e87; */
  background-color: #fcfcfc;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #eaeaea;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0;
  width: 95%;
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.logoContainer h6 {
  color: #eeeeee;
  font-size: 18px;
}

.logoImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoContainer img {
  width: 95px;
}

.loginBtn {
  display: flex;
  justify-content: center;
  background-color: #1d1e22 !important;
  width: 45px;
}

.loginBtn img {
  width: 16px;
}

.loginBtn:active {
  opacity: 0.9;
}

.navButtons {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
}

.navButtons button {
  background-color: #1d1e22;
  color: #eeeeee;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color, color 0.3s;
}

.navButtons button:last-child {
  padding: 0.5rem;
}

.navButtons button:active {
  opacity: 0.9;
  color: #ffffff;
}

.navButtons button:focus {
  outline: none;
  opacity: 0.9;
}
