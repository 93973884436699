.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 1rem; */
}

.checkOutWrap {
  background-color: #fcfcfc;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 5px 5px 0 0;
  color: #1d1e22;
  border-top: 1px solid #f6f6f6;
}

.checkOutWrap span {
  font-weight: 600;
  color: #1d1e22;
  font-size: 20px;
}

.checkOutWrap > p {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-decoration-skip-ink: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #8a8a8a;
}

.checkOutWrap button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 155px;
  width: 100%;
  gap: 0.3rem;
  background-color: #1d1e22;
  border: none;
  color: #eeeeee;
  font-weight: 600;
  padding: 0.8rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}

.checkOutForm {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  scroll-behavior: auto;
  justify-items: center;
  z-index: 10;
}

.nextGoatPopup {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}
