.backBar {
  padding: 0.8rem;
}

.backBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.carts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.cartItemsWrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  width: 95%;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantityButtons {
  display: flex;
}

.quantityButtons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  padding: 0.5rem;
  border-radius: 50%;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.quantityButtons input {
  width: 40px;
  text-align: center;
  border: none;
  margin: 0 4px;
  font-size: 14px;
}

.priceValues {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.verifyOtpContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  text-align: center;
  padding: 1rem;
  padding-bottom: 2rem;
  gap: 1rem;
  width: 100%;
  z-index: 10;
  bottom: 0;
  opacity: 1;
  background-color: white;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
}

.verifyOtpContainer h6 {
  font-weight: 500;
}

.verifyOtpContainer span {
  color: red;
  font-size: 12px;
}

.otpInputContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}

.otpInputContainer input {
  text-align: center;
  border: 1px solid black;
  width: 34px;
  height: 34px;
  border-radius: 5px;
}

.verifyButton {
  background-color: #1d1e22;
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
}

.checkmark {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.popupContent h1 {
  font-size: 24px;
  font-weight: 600;
}

.popupContent button {
  padding: 0.75rem 1.5rem;
  background-color: #1d1e22;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
  margin: 1rem 0;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}

/* HTML: <div class="loader"></div> */
.loginLoader {
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l6 1s infinite linear alternate;
}

@keyframes l6 {
  0% {
    box-shadow: 20px 0 #fff, -20px 0 #ffffff20;
    background: #fff;
  }
  33% {
    box-shadow: 20px 0 #fff, -20px 0 #ffffff20;
    background: #ffffff20;
  }
  66% {
    box-shadow: 20px 0 #ffffff20, -20px 0 #fff;
    background: #ffffff20;
  }
  100% {
    box-shadow: 20px 0 #ffffff20, -20px 0 #fff;
    background: #fff;
  }
}
